

/* body::-webkit-scrollbar {
	display: none;
	
  }
 */
  .navbar {
    background-color:inherit;
}
body {
  margin: 0;
  padding: 0;
}
 
.navbar.colorChange {
    background-color: white;
}
  .active {
	color: red;
  }
  
    .container { position:relative; }
  .container video {
	  position:relative;
	  z-index:0;
  }
  .overlay {
	  position:absolute;
	  top:0;
	  left:0;
	  
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
   
  .each-slide-effect > div {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	height: 550px;
  }
  
  .each-slide-effect span {
	padding: 20px;
	font-size: 20px;
	background: #efefef;
	text-align: center;
  }

  @media screen and (max-width:1024px) {
    .overlay {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      }
    }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
   
  

  /* *, *:before, *:after {
	box-sizing: inherit;
  }
  .cd__main{
	 background: linear-gradient(to right, #3f2b96, #a8c0ff) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 /* }
  .text-center {
	text-align: center;
  } */
  
  .color-white {
	color: #fff;
  }
  
  
 
   @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
